import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Typesetting",
  "hideInMenu": true,
  "path": "root"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Notebook of some experiments in typesetting.`}</p>
    <p>{`Signalwerk GmbH – Stefan Huber · 2020 · `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/4.0/"
      }}>{`CC-BY-SA 4.0`}</a></p>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      